/* eslint eqeqeq: 0 */
/* eslint react-hooks/exhaustive-deps: 0 */
/* no-mixed-operators: 0 */

import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { reduxForm, Field } from "redux-form";

import Form from "../../../../common/form/Form";
import FormHeader from "../../../../common/form/FormHeader";
import FormContent from "../../../../common/form/FormContent";

import GenericPickerInput from "../../../../common/input/GenericPickerInput";
import Input from "../../../../common/input/Input";
import Button from "../../../../common/button/Button";

import {
  getIndicadores,
  salvaFiltrosContasReceber,
  getContasReceber,
  loadFilterFormContasReceber,
} from "../../../../../actions/contasReceber/ContasReceberAction";

import { getOperacoes } from "../../../../../actions/operacao/OperacaoAction";

import { DropdownList } from "react-widgets";
import { getSugestaoPatrimonio } from "../../../../../actions/patrimonio/PatrimonioAction";
import { Link } from "react-router-dom";
import {
  NOVO_ROUTE_PATH,
  CONTAS_RECEBER_ROUTE_PATH,
} from "../../../../common/routers/constantesRoutes";

let ContasReceberListFilter = (props) => {
  const {
    getContasReceber,
    loadFilterFormContasReceber,
    getIndicadores,
    salvaFiltrosContasReceber,
    contasReceberConsult,

    getOperacoes,
    operacoesList,

    getSugestaoPatrimonio,
    patrimoniosList,
  } = props;

  const [idPatrimonioSeparado, setIdPatrimonioSeparado] = React.useState(
    contasReceberConsult?.idPatrimonioSeparado || null
  );
  const [dataInicio, setDataInicio] = React.useState(
    contasReceberConsult?.dataInicio || ""
  );
  const [dataFinal, setDataFinal] = React.useState(
    contasReceberConsult?.dataFinal || ""
  );
  const [idOperacao, setIdOperacao] = React.useState(
    contasReceberConsult?.idOperacao || null
  );
  const [idFaturarContra, setIdFaturarContra] = React.useState(
    contasReceberConsult?.idFaturarContra || null
  );
  const [idProdutoReceita, setIdProdutoReceita] = React.useState(
    contasReceberConsult?.idProdutoReceita || null
  );
  const [modalidade, setModalidade] = React.useState(
    contasReceberConsult?.modalidade || null
  );
  const [idOrigemRecurso, setIdOrigemRecurso] = React.useState(
    contasReceberConsult?.idOrigemRecurso || null
  );
  const [identificador, setIdentificador] = React.useState(
    contasReceberConsult?.identificador || ""
  );
  const [idsUltimasEtapas, setIdsUltimasEtapas] = React.useState(
    contasReceberConsult?.status || []
  );

  const [counterStatus, setCounterStatus] = React.useState(0);

  const chooseStatus = (key) => {
    if (idsUltimasEtapas.includes(key)) {
      let newArrIds = idsUltimasEtapas.filter((elemento) => elemento !== key);
      setIdsUltimasEtapas(newArrIds);
    } else {
      setIdsUltimasEtapas([...idsUltimasEtapas, key]);
    }
  };

  const checkStatus = (key) => {
    return idsUltimasEtapas?.some((o) => o == key) ? key : "";
  };

  const selecionarStatus = () => {
    const chips = Array.from(document.querySelectorAll(".pgmt-sts"));
    let arrStatus = [];

    chips.forEach((el) => arrStatus.push(Number(el.dataset.status)));

    setIdsUltimasEtapas(arrStatus);
  };

  const cleanStatus = () => {
    let arrStatus = [];
    setIdsUltimasEtapas(arrStatus);
  };

  async function searchFilters() {
    const data = {
      idPatrimonioSeparado,
      idOperacao,
      dataInicio,
      dataFinal,
      idFaturarContra,
      idProdutoReceita,
      modalidade,
      idOrigemRecurso,
      identificador,
      status: props.statusList.filter(({ key }) => idsUltimasEtapas.includes(key)),
    };

    await getContasReceber(data, contasReceberConsult?.pagingConfig?.pageIndex);
    await getIndicadores(data);
  }

  React.useEffect(() => {
    const fetchData = async () => {
      await loadFilterFormContasReceber();
      searchFilters();
    };

    fetchData();
  }, []);

  React.useEffect(() => {
    setCounterStatus(props.statusList?.length);
  }, [props.statusList]);

  // prettier-ignore
  React.useEffect(() => {
    salvaFiltrosContasReceber({
      idPatrimonioSeparado,
      idOperacao,
      dataInicio,
      dataFinal,
      idFaturarContra,
      idProdutoReceita,
      modalidade,
      idOrigemRecurso,
      identificador,
      status: props.statusList.filter(({ key }) => idsUltimasEtapas.includes(key)),
    })
  }, [idPatrimonioSeparado, idOperacao, dataInicio, dataFinal, idFaturarContra,
    idsUltimasEtapas, idProdutoReceita, modalidade, idOrigemRecurso, identificador]);

  const messages = {
    emptyList: "Nenhum registro encontrado",
    emptyFilter: "Nenhum registro encontrado com esse filtro",
  };

  return (
    <div className="container-fluid">
      <Form>
        <FormHeader title={"Contas a Receber"} />
        <FormContent
          classes="shadow-custom rounded-12 bg-white px-3 py-2"
          handleSubmit={getContasReceber}
        >
          <div className="row w-100 align-items-start mx-0 mt-2">
            <div className="col-12 col-xl align-self-start px-0 pr-xl-3 mb-xl-0 mb-3">
              <div className="d-flex flex-column">
                <label className="f-10-5 mb-2p lh-normal opacity-25 text-dark">
                  Patrimônio Separado
                </label>
                <GenericPickerInput
                  loadData={getSugestaoPatrimonio}
                  options={patrimoniosList}
                  activeClassName="inputPgmt"
                  formName={"ContasReceberForm"}
                  fieldName={"patrimonio"}
                  placeholder={"Pesquisar operação ex.:CRI.1.SEC.2"}
                  loadingMessage="Informe ao menos 3 caracteres para pesquisar"
                  noOptionsMessage="Nenhum registro encontrado"
                  value={idPatrimonioSeparado}
                  selectedOptionForm={(e) => setIdPatrimonioSeparado(e)}
                  isClearable
                />
              </div>
            </div>
            <div className="col-12 col-xl align-self-start px-0 pr-xl-3 mb-xl-0 mb-3">
              <div className="d-flex flex-column">
                <label className="f-10-5 mb-2p lh-normal opacity-25 text-dark">
                  Operação
                </label>
                <GenericPickerInput
                  loadData={getOperacoes}
                  options={operacoesList}
                  activeClassName="inputPgmt"
                  formName={"ContasReceberForm"}
                  fieldName={"operacao"}
                  placeholder={"Pesquisar operação ex.:CRI.1.SEC.2"}
                  loadingMessage="Informe ao menos 3 caracteres para pesquisar"
                  noOptionsMessage="Nenhum registro encontrado"
                  value={idOperacao}
                  selectedOptionForm={(e) => setIdOperacao(e)}
                  isClearable
                />
              </div>
            </div>

            <div className="col-12 col-xl-2 align-self-start px-0 pr-xl-3">
              <Field
                component={Input}
                outerClassName={"my-3"}
                className={"filter-input"}
                inputId="dataInicio"
                type={"date"}
                name="dataInicio"
                label="Data Início (Faturamento)"
                value={dataInicio}
                onChange={(e) => setDataInicio(e.target.value)}
              />
            </div>
            <div className="col-12 col-xl-2 align-self-start px-0 pr-xl-3">
              <Field
                component={Input}
                outerClassName={"my-3"}
                className={"filter-input"}
                inputId="dataFinal"
                type={"date"}
                name="dataFinal"
                label="Data Final (Faturamento)"
                value={dataFinal}
                onChange={(e) => setDataFinal(e.target.value)}
              />
            </div>
          </div>

          <div className="row w-100 align-items-start mx-0 mt-xl-2 mt-0">
            <div className="col-12 col-xl align-self-start px-0 pr-xl-3">
              <div className="d-flex flex-column">
                <label className="f-10-5 mb-2p mt-2p lh-normal opacity-25 text-dark">
                  Faturado Contra
                </label>
                <DropdownList
                  value={idFaturarContra || ""}
                  onChange={(e) => setIdFaturarContra(e?.id || null)}
                  valueField={"id"}
                  textField={"razaoSocialComCnpj"}
                  containerClassName="h-38-imp"
                  messages={messages}
                  placeholder={"Todas"}
                  filter={"contains"}
                  data={[
                    { id: null, razaoSocialComCnpj: "Selecione..." },
                    ...props.faturarContraList,
                  ]}
                />
              </div>
            </div>
            <div className="col-12 col-xl align-self-start px-0 pr-xl-3 mt-xl-0 mt-3">
              <div className="d-flex flex-column">
                <label className="f-10-5 mb-2p mt-2p lh-normal opacity-25 text-dark">
                  Tipo de Receita
                </label>
                <DropdownList
                  value={idProdutoReceita || ""}
                  onChange={(e) => setIdProdutoReceita(e?.id || null)}
                  valueField={"id"}
                  textField={"nome"}
                  containerClassName="h-38-imp"
                  messages={messages}
                  placeholder={"Todas"}
                  filter={"contains"}
                  data={[
                    { id: null, nome: "Selecione..." },
                    ...props.produtoReceitaList,
                  ]}
                />
              </div>
            </div>
            <div className="col-12 col-xl align-self-start px-0 pr-xl-3 mt-xl-0 mt-3">
              <div className="d-flex flex-column">
                <label className="f-10-5 mb-2p mt-2p lh-normal opacity-25 text-dark">
                  Modalidade
                </label>
                <DropdownList
                  value={modalidade?.value || ""}
                  onChange={(e) => setModalidade(e?.key ? e : null)}
                  valueField={"key"}
                  textField={"value"}
                  containerClassName="h-38-imp"
                  messages={messages}
                  placeholder={"Todas"}
                  filter={"contains"}
                  data={[
                    { key: null, value: "Selecione..." },
                    ...props.modalidadeList,
                  ]}
                />
              </div>
            </div>
            <div className="col-12 col-xl align-self-start px-0 pr-xl-3 my-xl-0 my-3">
              <div className="d-flex flex-column">
                <label className="f-10-5 mb-2p mt-2p lh-normal opacity-25 text-dark">
                  Origem dos Recursos
                </label>
                <DropdownList
                  value={idOrigemRecurso || ""}
                  onChange={(e) => setIdOrigemRecurso(e?.id || null)}
                  valueField={"id"}
                  textField={"descricao"}
                  containerClassName="h-38-imp"
                  messages={messages}
                  placeholder={"Todas"}
                  filter={"contains"}
                  data={[
                    { id: null, descricao: "Selecione..." },
                    ...props.origemRecursoList,
                  ]}
                />
              </div>
            </div>
            <div className="col-12 col-xl align-self-start px-0 mt-1px">
              <Field
                component={Input}
                outerClassName={"my-3"}
                className={"filter-input"}
                inputId="identificador"
                name="identificador"
                label="Identificador"
                value={identificador}
                onChange={(e_, v) => setIdentificador(v)}
              />
            </div>
          </div>

          {props.statusList.length > 0 && (
            <div className="row w-100 align-items-center mx-0 mb-2 mt-2 pl-0 pr-3">
              <span className="d-lg-block d-none col-lg-auto fw-500 f-12 pl-0 mb-1">
                Status
              </span>
              <div className="px-0 col-12 col-lg-11 w-100 d-flex flex-row flex-wrap align-items-center">
                {props.statusList.map((sts, index) => {
                  return (
                    <div
                      key={`sts-${index}`}
                      className="col-auto mb-1 px-2 tr-status"
                    >
                      <div
                        role="button"
                        onClick={() => chooseStatus(sts.key)}
                        className="d-flex flex-row align-items-center td-status prevent-select"
                      >
                        <div
                          data-status={sts.key}
                          className={`pgmt-sts circle-status sts-cr-${checkStatus(
                            sts.key
                          )} rounded-circle mr-1`}
                        ></div>
                        <p className="mb-0 mt-1px">{sts.value}</p>
                      </div>
                    </div>
                  );
                })}

                {idsUltimasEtapas.length < counterStatus && (
                  <span
                    onClick={selecionarStatus}
                    role="button"
                    className="text-underline p-color-lighten mb-xl-1 ml-2"
                  >
                    Selecionar todos
                  </span>
                )}
                {idsUltimasEtapas.length == counterStatus && (
                  <span
                    onClick={cleanStatus}
                    role="button"
                    className="text-underline p-color-lighten mb-xl-1 ml-2"
                  >
                    Desmarcar todos
                  </span>
                )}
              </div>
            </div>
          )}

          <div className="row w-100 mx-0">
            <div className="col-xl-2 col-12 px-0 pr-xl-3">
              <Button
                activeClassName="w-100"
                action={() => searchFilters()}
                classes="p-bg-imp rounded btn-search h-38 mt-3 w-100 d-flex justify-content-center align-items-center"
                nameClass="text-capitalize f-14 lh-1 mt-1px"
                name={"Buscar"}
              />
            </div>
            <div className="col-xl-2 col-12 px-0">
              <Link to={`/${CONTAS_RECEBER_ROUTE_PATH}/${NOVO_ROUTE_PATH}`}>
                <Button
                  activeClassName="w-100"
                  classes="darkblue-bg-imp rounded btn-search h-38 mt-3 w-100 d-flex justify-content-center align-items-center"
                  icon="plus-circle mr-2"
                  nameClass="mt-2px text-capitalize f-14"
                  name={"Criar Registro"}
                />
              </Link>
            </div>
          </div>
        </FormContent>
      </Form>
    </div>
  );
};

ContasReceberListFilter = reduxForm({ form: "ContasReceberForm" })(
  ContasReceberListFilter
);

const mapStateToProps = (state) => ({
  patrimoniosList: state.patrimonioReducer.patrimoniosOptions,
  operacoesList: state.operacaoReducer.operacoes,
  faturarContraList:
    state.contasReceberReducer.contasReceberFormTypesFilter.faturarContraList ||
    [],
  produtoReceitaList:
    state.contasReceberReducer.contasReceberFormTypesFilter
      .produtoReceitaList || [],
  modalidadeList:
    state.contasReceberReducer.contasReceberFormTypesFilter.modalidadeList ||
    [],
  origemRecursoList:
    state.contasReceberReducer.contasReceberFormTypesFilter.origemRecursoList ||
    [],
  contasReceberConsult: state.contasReceberReducer.contasReceberConsultCurrent,
  statusList:
    state.contasReceberReducer.contasReceberFormTypesFilter.status || [],
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getContasReceber,
      loadFilterFormContasReceber,
      getIndicadores,
      salvaFiltrosContasReceber,
      getOperacoes,
      getSugestaoPatrimonio,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ContasReceberListFilter);
