/* eslint eqeqeq: 0 */

import { dataMaiorQue } from "../../../../../../utils/helpers/DateTimeHelper";
import { emailValido } from "../../../../../../utils/helpers/EmailHelper";

// const currEnv = localStorage.getItem("currEnv") ?? localStorage.getItem("currEnv");
// const gestora = currEnv == process.env.REACT_APP_ENV_GEST_ID;

export const validate = (values) => {
  const statusValue = values?.statusAprovacao?.key;
  const APROVADO = statusValue == 3;

  const CL = values.contatos;
  const divisoesFlat = values?.receitaContratoFlat?.divisoes;
  const divisoesRecorrente = values?.receitaContratoRecorrente?.divisoes;

  const possuiPmtFlat = values?.possuiPagamentoFlat == "Sim";
  const possuiPmtRecorrente = values?.possuiPagamentoRecorrente == "Sim";
  const possuiCorrecaoRecorrente = values?.receitaContratoRecorrente?.possuiCorrecao == "Sim";

  const pagamentoProporcionalFlat = values.receitaContratoFlat?.pagamentoProporcional == "Sim"
  const pagamentoProporcionalRec = values.receitaContratoRecorrente?.pagamentoProporcional == "Sim"

  const idOrigemRecursoFlat = values.receitaContratoFlat?.idOrigemRecurso
  const idOrigemRecursoRec = values.receitaContratoRecorrente?.idOrigemRecurso

  let errors = {
    // Contrato
    idEmpresaFaturamento: !values.idEmpresaFaturamento ? "Preenchimento obrigatório" : null,
    idProdutoReceita: !values.idProdutoReceita ? "Preenchimento obrigatório" : null,
    observacao: !values.observacao ? "Preenchimento obrigatório" : null,
    
    // Pagamento Flat
    possuiPagamentoFlat: !values.possuiPagamentoFlat ? "Preenchimento obrigatório" : null,
    receitaContratoFlat: {
      valorLiquido: possuiPmtFlat && (values.receitaContratoFlat?.valorLiquido == null || values.receitaContratoFlat?.valorLiquido === '') ? "Preenchimento obrigatório" : null,
      grossUp: possuiPmtFlat && !values.receitaContratoFlat?.grossUp ? "Preenchimento obrigatório" : null,
      valorBruto: possuiPmtFlat && (values.receitaContratoFlat?.valorBruto == null || values.receitaContratoFlat?.valorBruto === '') ? "Preenchimento obrigatório" : null,
      dataPagamento: possuiPmtFlat && !values.receitaContratoFlat?.dataPagamento ? "Preenchimento obrigatório" : null,
      formaPagamento: possuiPmtFlat && !values.receitaContratoFlat?.formaPagamento ? "Preenchimento obrigatório" : null,
      idOrigemRecurso: possuiPmtFlat && !values.receitaContratoFlat?.idOrigemRecurso ? "Preenchimento obrigatório" : null,
      idContaOrigem: possuiPmtFlat && !values.receitaContratoFlat?.idContaOrigem && idOrigemRecursoFlat?.id !== 20 ? "Preenchimento obrigatório" : null,
      pagamentoProporcional: possuiPmtFlat && values.receitaContratoFlat?.pagamentoProporcional == null ? "Preenchimento obrigatório" : null,
      divisoes: possuiPmtFlat && (!divisoesFlat || divisoesFlat.length < 1) 
        ? "É necessário adicionar ao menos um pagamento" 
        : divisoesFlat?.map((d) => ({
              idPessoa: !d.idPessoa ? "Preenchimento obrigatório" : null,
              proporcao: d.proporcao == null || d.proporcao === '' ? "Preenchimento obrigatório" : null
            })),
      somaDivisoes: pagamentoProporcionalFlat && divisoesFlat && divisoesFlat.reduce((sum, d) => sum + (d.proporcao || 0), 0) !== 100 
        ? "As proporções devem somar 100%" 
        : null,
    },

    
    // Pagamento Recorrente
    possuiPagamentoRecorrente: !values.possuiPagamentoRecorrente ? "Preenchimento obrigatório" : null,
    receitaContratoRecorrente: {
      valorLiquido: possuiPmtRecorrente && (values.receitaContratoRecorrente?.valorLiquido == null || values.receitaContratoRecorrente?.valorLiquido === '') ? "Preenchimento obrigatório" : null,
      grossUp: possuiPmtRecorrente && !values.receitaContratoRecorrente?.grossUp ? "Preenchimento obrigatório" : null,
      valorBruto: possuiPmtRecorrente && (values.receitaContratoRecorrente?.valorBruto == null || values.receitaContratoRecorrente?.valorBruto === '') ? "Preenchimento obrigatório" : null,
      dataInicio: possuiPmtRecorrente && !values.receitaContratoRecorrente?.dataInicio ? "Preenchimento obrigatório" : null,
      dataVencimento: (possuiPmtRecorrente && !values.receitaContratoRecorrente?.dataVencimento) ? "Preenchimento obrigatório" : (possuiPmtRecorrente && !dataMaiorQue(values.receitaContratoRecorrente?.dataVencimento, values?.receitaContratoRecorrente?.dataInicio)) ? "A Data de Vencimento deve ser posterior à Data de Início" : null,
      idContaOrigem: possuiPmtRecorrente && !values.receitaContratoRecorrente?.idContaOrigem && idOrigemRecursoRec?.id !== 20 ? "Preenchimento obrigatório" : null,
      idOrigemRecurso: possuiPmtRecorrente && !values.receitaContratoRecorrente?.idOrigemRecurso ? "Preenchimento obrigatório" : null,
      modalidade: possuiPmtRecorrente && !values.receitaContratoRecorrente?.modalidade ? "Preenchimento obrigatório" : null,
      diaFaturamento: possuiPmtRecorrente && !values.receitaContratoRecorrente?.diaFaturamento ? "Preenchimento obrigatório" : null,
      prazoPagamento: possuiPmtRecorrente && !values.receitaContratoRecorrente?.prazoPagamento ? "Preenchimento obrigatório" : null,
      idPeriodicidadePagamento: possuiPmtRecorrente && !values.receitaContratoRecorrente?.idPeriodicidadePagamento ? "Preenchimento obrigatório" : null,
      mesesPagamento: possuiPmtRecorrente && (!values.receitaContratoRecorrente?.mesesPagamento || values.receitaContratoRecorrente?.mesesPagamento?.length < 1) ? "Preenchimento obrigatório" : null,
      quantidadeParcelas: possuiPmtRecorrente && !values.receitaContratoRecorrente?.quantidadeParcelas ? "Preenchimento obrigatório" : null,
      valorContrato: possuiPmtRecorrente && (values.receitaContratoRecorrente?.valorContrato == null || values.receitaContratoRecorrente?.valorContrato === '') ? "Preenchimento obrigatório" : null,
      possuiCorrecao: possuiPmtRecorrente && !values.receitaContratoRecorrente?.possuiCorrecao ? "Preenchimento obrigatório" : null,
      pagamentoProporcional: possuiPmtRecorrente && values.receitaContratoRecorrente?.pagamentoProporcional == null ? "Preenchimento obrigatório" : null,
      divisoes: possuiPmtRecorrente && (!divisoesRecorrente || divisoesRecorrente.length < 1) 
        ? "É necessário adicionar ao menos um pagamento" 
        : divisoesRecorrente?.map((d) => ({
              idPessoa: !d.idPessoa ? "Preenchimento obrigatório" : null,
              proporcao: d.proporcao == null || d.proporcao === '' ? "Preenchimento obrigatório" : null
            })),
      somaDivisoes: pagamentoProporcionalRec && divisoesRecorrente && divisoesRecorrente.reduce((sum, d) => sum + (d.proporcao || 0), 0) !== 100 
        ? "As proporções devem somar 100%" 
        : null,
    },

    // Contato
    contatos: CL && CL.length
      ? CL.map((ct) => ({
          nome: !ct.nome ? "Preenchimento obrigatório" : null,
          telefone: !ct.telefone ? "Preenchimento obrigatório" : ct.telefone.length < 14 ? "Formato inválido" : null,
          email: !ct.email ? "Preenchimento obrigatório" : !emailValido(ct.email) ? "Formato de email inválido" : null
        }))
      : [{ nome: null, telefone: null, email: null }],
  }

  if (possuiCorrecaoRecorrente) {
    errors = {
      ...errors,
      receitaContratoRecorrente: {
        ...errors.receitaContratoRecorrente,
        idTaxa: possuiPmtRecorrente && !values.receitaContratoRecorrente?.idTaxa ? "Preenchimento obrigatório" : null,
        mesesDefasagem: possuiPmtRecorrente && (values.receitaContratoRecorrente?.mesesDefasagem == null) ? "Preenchimento obrigatório" : null,
        idPeriodicidadeCorrecao: possuiPmtRecorrente && !values.receitaContratoRecorrente?.idPeriodicidadeCorrecao ? "Preenchimento obrigatório" : null,
        mesesCorrecao: possuiPmtRecorrente && (!values.receitaContratoRecorrente?.mesesCorrecao || values.receitaContratoRecorrente?.mesesCorrecao?.length < 1) ? "Preenchimento obrigatório" : null,
        somenteVariacaoPositiva: possuiPmtRecorrente && !values.receitaContratoRecorrente?.somenteVariacaoPositiva ? "Preenchimento obrigatório" : null,
        dataInicioCorrecao: possuiPmtRecorrente && !values.receitaContratoRecorrente?.dataInicioCorrecao ? "Preenchimento obrigatório" : null,
      }
    }
  }

  if (APROVADO) {
    errors = {
      ...errors,
      status: !values.status ? "Preenchimento obrigatório" : null,
      dataUltimaParcela: !values.dataUltimaParcela ? "Preenchimento obrigatório" : null,
    }
  }

  return errors;
};
